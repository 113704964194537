.theme-bg {
  background: #990000;
  .footer-wrap {
    .footer-credits {
      color: #ffffff;
      color: rgb(255, 255, 255);
      a {
        color: #ffffff;
      }
    }
  }
}
.theme-card {
  background: #fff;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.javascript-required-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  h3 {
    text-align: center;
  }
}
.javascript-required-content {
  display: flex;
  justify-content: center;
}
.fixed-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1049;
  .container {
    width: 100%;
    max-width: 1248px;
    margin: auto;
    padding-left: 24px;
    padding-right: 24px;
  }
}
.footer-wrap {
  border-top: 1px solid rgb(230, 228, 231);
  padding: 24px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .footer-credits {
    margin: 16px 0px;
    font-family: DMSansRegular;
    font-size: 16px;
    line-height: 24px;
    color: rgb(90, 88, 91);
  }
}
.error-page-box{
  padding-top: 150px;
}
.error-page-wrap{
  max-width: 550px;
  color: #FFFFFF;
  h3{
    line-height: 1.5;
    text-align: left;
    font-size: 40px;
    margin-bottom: 20px;
  }
  h4{
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 24px;
  }
  h5{
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 24px;
  }
  p{
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 4px;
  }
  a{
    display: inline-block;
    color: #333;
    background-color: #FFFFFF;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
  }
}