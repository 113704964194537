.downloads-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.quota {
  .quota-text {
    font-size: 16px;
    line-height: 28px;
  }
  .quota-value {
    display: block;
    font-size: 24px;
    line-height: 32px;
    font-family: "DMSansMedium";
  }
  .quota-value-wrap {
    display: flex;
    align-items: center;
  }
  .quota-progress {
    display: inline-block;
    margin-left: 24px;
    height: 12px;
    width: 48px;
    border-radius: 6px;
    background: #ecefff;
    overflow: hidden;
    line-height: 0;
    .quota-progress-value {
      display: inline-block;
      // background-color: #990000;
      height: 12px;
    }
  }
}
.delete-all {
  display: flex;
  align-items: center;
  font-family: "DMSansRegular";
  line-height: 28px;
  height: 28px;
  font-size: 16px;
  cursor: pointer;
  background: #990000;
  // background: transparent;
  border: none;
  border-radius: 6px;
  padding: 24px 10px;
  color: white;
  // color: #858287;
  margin-top: 24px;
  i {
    margin-right: 12px;
    font-size: 25px;
  }
  &:hover {
    color: #f0eded;
    background-color: #bc1010;
    span {
      text-decoration: underline;
    }
  }
}
.no-downloads-wrap {
  margin-bottom: 56px;
  p {
    margin: 16px 0;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    i {
      color: #990000;
      font-size: 24px;
    }
  }
}

@media (max-width: 767px) {
  .downloads-wrap {
    display: block;
  }
}
