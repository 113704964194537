.login-wrap{
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  .login-logo{
    margin: 10px auto 30px;
    width: 160px;
    img{
      width: 100%;
      height: auto;
      margin: auto;
    }
  }
}
.login-box{
  background: linear-gradient(45deg,#b81212 0%,#f16f5c 0%,#900 83%,#900 94%,#900 100%);
  min-height: 100vh;
  padding: 20px 0;
}

.login-form{
  background: #fff;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.30);
  h2{
    text-align: center;
    font-size: 20px;
    line-height: 22px;
    font-weight: 400;
    margin-bottom:20px;
  }
}

.form-controls{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  a{
    color: #900;
    line-height: 17px;
  }
}

.copy-right{
  margin: 30px 0;
  p{
    display: block;
    color: #fff;
    text-align: center;
  }
}

.white-text {
  a, span{

    color: white!important;
  }
  .footer-wrap{
    border-top: none;
  }
}

