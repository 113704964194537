.footer-wrap{
  border-top: 1px solid #e6e4e7;
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  .footer-credits{
    margin: 16px 0;
    font-family: 'DMSansRegular';
    font-size: 16px;
    line-height: 24px;
    color: #5a585b;
    width: 100%;
    text-align: center;
    span{
      &:nth-child(1){
        margin-right: 24px;
      }
    }
    a{
      color: #000;
      &:hover{
        text-decoration: none;
      }
    }
  }
  .trouble-shoot {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: #5a585b;
  }
}

@media (max-width: 991px) {
  .footer-wrap{
    padding: 16px 0;
    justify-content: center;
    .footer-credits{
      margin: 16px 0;
      font-size: 16px;
      line-height: 26px;
      display: flex;
      flex-direction: column;
      align-items: center;
      span{
        &:nth-child(1){
          margin-right: 0;
        }
      }
      a{
        color: #000;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
}