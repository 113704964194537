@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon/icomoon.eot?95d5v1');
    src:  url('../fonts/icomoon/icomoon.eot?95d5v1#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.ttf?95d5v1') format('truetype'),
    url('../fonts/icomoon/icomoon.woff?95d5v1') format('woff'),
    url('../fonts/icomoon/icomoon.svg?95d5v1#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
    content: "\e900";
}
.icon-info:before {
    content: "\e901";
}
.icon-trash:before {
    content: "\e902";
}
.icon-user:before {
    content: "\e903";
}
.icon-download-alt:before {
    content: "\e904";
}
.icon-downloads:before {
    content: "\e905";
}
.icon-hamburger:before {
    content: "\e906";
}
.icon-x:before {
    content: "\e907";
}