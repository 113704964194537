.page-header {
  margin-bottom: 64px;
  // max-width: 690px;
  h1 {
    font-size: 48px;
    font-family: "DMSansRegular";
    font-weight: 700;
    line-height: 58px;
    margin-top: 32px;
    margin-bottom: 22px;
  }
  p {
    margin: 18px 0;
    font-family: "DMSansRegular";
    font-size: 18px;
    line-height: 32px;
    font-weight: 600;
    span {
      font-weight: 700;
    }
  }
}

.categories-section-box {
  .categories-section-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-family: "DMSansRegular";
      font-size: 32px;
      font-weight: 700;
      line-height: 44px;
      letter-spacing: -0.02em;
    }
    a {
      font-family: "DMSansMedium";
      background-color: rgba(#990000, 0.1);
      padding: 10px 20px;
      border-radius: 8px;
      color: #990000;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      transition: color 100ms ease-in-out, background-color 100ms ease-in-out;
      &:hover {
        background-color: #990000;
        color: #ffffff;
      }
    }
  }
}

.video-cards-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 24px -12px 64px;
  .video-cards-row {
    margin-bottom: 30px;
    &.w-33 {
      width: 33.333%;
    }
    &.w-50 {
      width: 50%;
    }
    padding: 0 12px;
  }
}

.video-cards {
  a {
    display: inline-block;
    &:hover {
      img {
        transform: scale(1.03);
      }
      ~ .downloaded-txt {
        transform: scale(1.2);
        right: 4px !important;
      }
    }
    &.img-wrapper {
      min-height: 218px;
      img {
        opacity: 0.5;
        transition: transform 0.2s cubic-bezier(0, 0, 0.4, 1);
        border-radius: 8px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .thumbnail-container {
    position: relative;
    cursor: pointer;

    .play-button {
      width: 20%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    span.downloaded-txt {
      position: absolute;
      top: 0%;
      right: 0%;
      color: white;
      background: red;
      padding: 5px;
      border-bottom-left-radius: 8px;
      border-top-right-radius: 8px;
      transition: cubic-bezier(0, 0, 0.4, 1) 0.2s;
    }
  }
  a:hover .thumbnail-container {
    transform: scale(1.03);
  }

  .video-cards-desc {
    position: relative;
    h2 {
      font-family: "DMSansMedium";
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      margin: 20px 40px 20px 0;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    p {
      font-family: "DMSansRegular";
      font-size: 16px;
      line-height: 24px;
      display: block;
      margin: 16px 0;
    }
  }
}

.download-btn {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 18px;
  min-width: 34px;
  width: auto;
  height: 34px;
  padding: 4px;
  color: #990000;
  background: #ffffff;
  border: 1px solid #990000;
  border-radius: 8px;

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  span {
    display: none;
  }

  &:disabled {
    border: 1px solid #666666;
    color: #666666;
  }
  &:hover {
    span {
      display: inline-block;
      padding-right: 22px;
    }

    i {
      right: 4px;
      left: auto;
      transform: translateY(-50%);
    }
  }

  &.download-inprogress {
    height: auto;
    min-width: 177px;
  }

  &:hover {
    background-color: #990000;
    color: #ffffff;
  }
}
.announcement-box {
  margin-bottom: 24px;
  h2 {
    font-family: "DMSansRegular";
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.02em;
    margin-bottom: 24px;
  }
  .card {
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid rgb(230, 228, 231);
    box-shadow: 0 0 1px rgb(230, 228, 231);
  }
  .announcement-content-wrap {
    background-color: #fbac83;
    padding: 16px 24px;
    h4 {
      font-size: 18px;
      margin-bottom: 16px;
      font-family: "DMSansMedium";
    }
    ul {
      font-family: "DMSansRegular";
      font-size: 16px;
      margin-bottom: 16px;
      li {
        margin-bottom: 4px;
      }
    }
    .link {
      font-family: "DMSansMedium";
      color: #1d80a3;
      display: inline-block;
      font-size: 18px;
      font-weight: 600;
    }
  }
}

@media (max-width: 991px) {
  .video-cards-wrap {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin-left: -8px;
    margin-right: -8px;
    scrollbar-width: none; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    .video-cards-row {
      padding: 0 8px;
      &.w-33,
      &.w-50 {
        min-width: calc(50% - 50px);
      }
    }
  }
  .video-cards {
    a {
      &.img-wrapper {
        min-height: inherit;
      }
    }
  }
  .page-header {
    margin-bottom: 48px;
    p {
      margin: 16px 0;
      font-size: 16px;
      line-height: 30px;
    }
  }
  .categories-section-box {
    .categories-section-top {
      h2 {
        margin-right: 6px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      a {
        min-width: 98px;
      }
    }
  }
}

@media (max-width: 575px) {
  .video-cards-wrap {
    display: flex;
    overflow-x: auto;
    scrollbar-width: none; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    .video-cards-row {
      &.w-33,
      &.w-50 {
        min-width: calc(100% - 50px);
      }
    }
  }
}
