* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body,
html {
  font-size: 14px;
  line-height: 130%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "RobotoRegular";
  min-height: 100vh;
  color: #333;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  font-family: "DMSansRegular";
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.75);
}

a,
a:hover {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.75);
}

.grayscale {
  filter: grayscale(100%);
}

.container {
  width: 100%;
  max-width: 1248px;
  margin: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.d-none {
  display: none;
}

.form-group {
  margin-bottom: 30px;
  .form-label {
    display: inline-block;
    padding: 0;
    font-family: "RobotoMedium";
    color: #333;
    text-transform: uppercase;
    position: relative;
    line-height: 16px;
    margin-bottom: 10px;
    i {
      font-size: 12px;
    }
  }
  .form-input {
    width: 100%;
    display: block;
    background-color: #fff;
    border: 1px solid #ccc;
    outline: none;
    padding: 7px 10px;
    font-size: 15px;
    color: #333;
    height: 50px;
  }
  .error {
    color: #990000;
  }
}

//Global buttons
.btn {
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}
.btn-red {
  width: 100%;
  margin-top: 25px;
  text-transform: uppercase;
  padding: 12px;
  font-size: 16px;
  line-height: 20px;
  background: #900;
  border: 1px solid #900;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
}

//Text
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "DMSansRegular";
}

//Global checkbox
.custom-checkbox {
  position: relative;
  .label-text {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #999;
    margin-right: 10px;
    padding-left: 24px;
    a {
      font-family: "OpenSans-SemiBold";
      color: #1961ac;
    }
  }
  .checkbox-wrap {
    min-width: 18px;
    width: 18px;
    height: 18px;
    border: 1px solid #ccc;
    display: inline-block;
    margin-right: 8px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #fff;
    cursor: pointer;
    &:after {
      content: "";
      position: absolute;
      left: 5px;
      top: 0;
      width: 5px;
      height: 10px;
      border: solid #ffffff;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      display: none;
    }
  }
  input[type="checkbox"] {
    display: none;
    &:checked {
      & ~ .checkbox-wrap {
        background-color: #900;
        border-color: #cccccc;
        &:after {
          display: block;
        }
      }
    }
  }
}

.perfect-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 991px) {
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.shaka-video-container {
  overflow: hidden;
}

.watermark {
  position: absolute;
  padding: 20px;
  color: #15f4ee;
  font-family: Arial;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  white-space: nowrap;
}
