.custom-checkbox {
  position: relative;
  .label-text {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #333333;
    margin-right: 10px;
    padding-left: 24px;
    a{
      font-family: 'OpenSans-SemiBold';
      color: #990000;
    }
  }
  .checkbox-wrap {
    min-width: 16px;
    width: 16px;
    height: 16px;
    border: 1px solid #990000;
    border-radius: 3px;
    display: inline-block;
    margin-right: 8px;
    position: absolute;
    left: 0;
    top: 2px;
    background-color: #fff;
    cursor: pointer;
    &:after {
      content: "";
      position: absolute;
      left: 5px;
      top: 3px;
      width: 3px;
      height: 6px;
      border: solid #FFFFFF;
      border-width: 0 1px 1px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      display: none;
    }
  }
  input[type="checkbox"] {
    display: none;
    &:checked {
      & ~ .checkbox-wrap {
        background-color: #990000;
        border-color: #990000;
        &:after {
          display: block;
        }
      }
    }
  }
}