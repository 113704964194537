
.profile-section-top{
  margin-bottom: 20px;
  h2{
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.02em;
  }
}

.profile-img-wrap{
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px ;
  min-height: 100px; //Remove
  .profile-img-left{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100px;
    height: 100px;
    background-color: #990000;
    color: #FFFFFF;
    border-radius: 50%;
    span{
      font-size: 40px;
      font-family: 'DMSansMedium';
    }
  }
  .profile-sec-right{
    padding-left: 120px;
    h3{
      font-size: 24px;
      font-family: 'DMSansMedium';
      line-height: 30px;
      margin-bottom: 8px;
    }
    p{
      color: #5a585b;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.profile-info-wrap{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px 30px;
  .profile-info-list{
    padding: 0 20px;
    width: 50%;
    margin-bottom: 20px;
    &:nth-last-child(1){
      margin-bottom: 0;
    }
    .label-text{
      font-size: 12px;
      color: #5a585b;
      margin-bottom: 4px;
      display: inline-block;
    }
    p{
      font-family: 'DMSansMedium';
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.profile-role-group{
  display: flex;
  margin: 0 -8px;
  flex-wrap: wrap;
  .profile-role-badges-row{
    padding: 8px;
  }
}
.profile-role-badge{
  display: inline-block;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 20px;
  background-color: transparent;
  color: #990000;
  border: 1px solid #990000;
  border-radius: 100px;
}

@media (max-width: 767px) {

  .profile-section-top{
    margin-bottom: 12px;
    h2{
      font-size: 24px;
      line-height: 30px;
    }
  }

  .profile-img-wrap{
    margin-bottom: 12px;
    min-height: 60px; //Remove
    .profile-img-left{
      width: 60px;
      height: 60px;
      span{
        font-size: 24px;
      }
    }
    .profile-sec-right{
      padding-left: 72px;
      h3{
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 0;
      }
    }
  }

  .profile-info-wrap{
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 30px;
    .profile-info-list{
      padding: 0;
      width: 100%;
      margin-bottom: 12px;
      p{
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .profile-role-group{
    margin: 0 -4px;
    .profile-role-badges-row{
      padding: 4px;
    }
  }
  .profile-role-badge{
    padding: 4px 8px;
    font-size: 14px;
    line-height: 16px;
  }
}