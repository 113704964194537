.header-wrap {
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo-wrap {
    width: 100px;

    img {
      width: 100%;
      height: auto;
    }
  }
}

button {
  color: #ffffff;
}

header {
  background: #990000;
  margin-bottom: 2rem;
}

.header-menu {
  display: flex;
  list-style-type: none;

  li {
    margin-right: 18px;
    position: relative;

    &:nth-last-child(1) {
      margin-right: 0;
    }

    a {
      font-family: "DMSansRegular";
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      display: block;
      padding: 8px 12px;

      &.active,
      &:hover {
        span {
          text-decoration: underline;
        }

        .icon {
          color: #ffffff;
        }
      }
    }
    .icon {
      margin-right: 8px;
      font-size: 24px;
      vertical-align: middle;
      color: #ffffff;
    }
  }
}

.hamburger-menu {
  display: none;
  color: #6e6c6f;
  font-size: 24px;
}

.user-profile-wrap {
  position: absolute;
  right: 0;
  top: 100%;
  min-width: 120px;
  max-width: 200px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(#990000, 0.4);
  display: none;

  &.active {
    display: block;
    z-index: 99999999;
  }
  ul {
    list-style: none;

    li {
      a {
        padding: 13px 20px;
        display: block;
      }
    }
  }
}

.profile-btn {
  span {
    // display: none;
  }
}

@media (max-width: 991px) {
  .header-menu {
    position: fixed;
    z-index: 10;
    margin-top: 88px;
    top: 0;
    background: white;
    left: 0;
    height: calc(100vh - 88px);
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    transform: translateX(100vw);
    transition: transform 70ms ease-in-out;
    &.active {
      display: block;
      transform: translateX(0);
    }

    li {
      width: 100%;

      a {
        padding: 24px 16px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        background-color: rgba(#990000, 1);

        &.active,
        &:hover {
          span {
            color: #ffffff;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .hamburger-menu {
    display: block;
    position: relative;
    color: #ffffff;
    span {
      font-size: 16px;
      line-height: 24px;
    }
    &:before {
      content: "\e906";
      position: absolute;
      font-family: "icomoon";
      top: 50%;
      left: -32px;
      transform: translateY(-50%);
    }
    &.active {
      &:before {
        content: "\e907";
        font-family: "icomoon";
      }
    }
  }
  .user-profile-wrap {
    width: 100%;
    max-width: none;
    ul {
      li {
        a {
          padding: 24px 16px;
        }
      }
    }
  }
  .profile-btn {
    span {
      display: inline-block;
    }
  }
}
