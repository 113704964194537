
@font-face {
    font-family: 'RobotoRegular';
    src: url('../fonts/roboto-sans/Roboto-Regular.eot');
    src: url('../fonts/roboto-sans/Roboto-Regular.eot') format('embedded-opentype'),
    url('../fonts/roboto-sans/Roboto-Regular.woff2') format('woff2'),
    url('../fonts/roboto-sans/Roboto-Regular.woff') format('woff'),
    url('../fonts/roboto-sans/Roboto-Regular.ttf') format('truetype'),
    url('../fonts/roboto-sans/Roboto-Regular.svg#OpenSansMedium') format('svg');
}

@font-face {
    font-family: 'RobotoMedium';
    src: url('../fonts/roboto-sans/Roboto-Medium.eot');
    src: url('../fonts/roboto-sans/Roboto-Medium.eot') format('embedded-opentype'),
    url('../fonts/roboto-sans/Roboto-Medium.woff2') format('woff2'),
    url('../fonts/roboto-sans/Roboto-Medium.woff') format('woff'),
    url('../fonts/roboto-sans/Roboto-Medium.ttf') format('truetype'),
    url('../fonts/roboto-sans/Roboto-Medium.svg#OpenSansMedium') format('svg');
}

@font-face {
    font-family: 'DMSansRegular';
    src: url('../fonts/dm-sans/DMSans-Regular.eot');
    src: url('../fonts/dm-sans/DMSans-Regular.eot') format('embedded-opentype'),
    url('../fonts/dm-sans/DMSans-Regular.woff2') format('woff2'),
    url('../fonts/dm-sans/DMSans-Regular.woff') format('woff'),
    url('../fonts/dm-sans/DMSans-Regular.ttf') format('truetype'),
    url('../fonts/dm-sans/DMSans-Regular.svg#OpenSansMedium') format('svg');
}

@font-face {
    font-family: 'DMSansMedium';
    src: url('../fonts/dm-sans/DMSans-Medium.eot');
    src: url('../fonts/dm-sans/DMSans-Medium.eot') format('embedded-opentype'),
    url('../fonts/dm-sans/DMSans-Medium.woff2') format('woff2'),
    url('../fonts/dm-sans/DMSans-Medium.woff') format('woff'),
    url('../fonts/dm-sans/DMSans-Medium.ttf') format('truetype'),
    url('../fonts/dm-sans/DMSans-Medium.svg#OpenSansMedium') format('svg');
}