.btn_menu {
  display: flex;
  width: 100%;
  align-items: center;
  font-family: "DMSansRegular";
  line-height: 28px;
  height: 28px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  padding: 20px;
  color: #858287;
  border-radius: 5px;
}
.btn_menu:hover {
  background: #858287;
  color: #ffffff;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.btn_menu i {
  margin-right: 12px;
  font-size: 25px;
}

.li {
  padding: 4px 12px !important;
  width: 100%;
}

.topBottomPadding {
  padding: 4px 0;
}

.profile_btn {
  display: flex!important;
  align-items: center;
  justify-content: center;
  .hovered {
    color: #ffffff;
  }
}
