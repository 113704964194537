.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1072;
  overflow: hidden;
  outline: 0;
  transition: all 0.15s linear;
  //opacity: 0;
  &.show {
    opacity: 1;
  }
  .modal-dialog {
    position: relative;
    width: auto;
    max-width: 900px;
    margin: 24px auto;
    pointer-events: none;
    display: flex;
    align-items: center;
    min-height: calc(100vh - (2 * 24px));
  }
  .modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
  }
  .modal-body {
    padding: 32px;
    overflow: auto;
    max-height: calc(100vh - 48px);
    .modal-title {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 40px;
      text-align: center;
      text-decoration: underline;
    }
    .modal-list {
      li {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 8px;
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }
  }
}

.checkbox-section-wrap {
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 16px;
  background-color: rgba(#333333, 0.2);
}

.modal-btns {
  display: flex;
  justify-content: flex-end;
  .btn {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }
}
